
export function stringToHexColor(str: string, opacity = false): string {
  const hexSize = opacity ? 8 : 6;
  const hexRegex = new RegExp(`^#([0-9a-f]{${hexSize}})$`, "i");
  const hex = str.match(hexRegex);

  if (hex) {
    return hex[0];
  }

  let hexColor = removeNonHexCharacters(str);
  if (hexColor.length === 3) {
    hexColor =
      hexColor[0] +
      hexColor[0] +
      hexColor[1] +
      hexColor[1] +
      hexColor[2] +
      hexColor[2];
  } else if (hexColor.length < hexSize) {
    // complete the hexColor
    hexColor += "0".repeat(6 - hexColor.length);
  }

  return `#${hexColor.slice(0, hexSize)}`;
}

function removeNonHexCharacters(str: string): string {
  let hexOnly = "";
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    if (
      (charCode >= 48 && charCode <= 57) || // 0-9
      (charCode >= 65 && charCode <= 70) || // A-F
      (charCode >= 97 && charCode <= 102) // a-f
    ) {
      hexOnly += str[i];
    }
  }
  return hexOnly;
}