<template>
  <div ref="colorPickerElement"></div>
</template>

<script lang="ts" setup>
import "@simonwep/pickr/dist/themes/nano.min.css"; // 'nano' theme
import Pickr from "@simonwep/pickr";

const emit = defineEmits<{
  (e: "change", value: string): void;
  (e: "cancel"): void;
}>();

interface IColorPickerProps {
  color: string;
  options?: {
    opacity?: boolean;
  };
}

const props = defineProps<IColorPickerProps>();

onMounted(() => {
  setTimeout(() => {
    createColorPicker();
  }, 10);
});

const colorPickerElement = ref<HTMLElement | null>(null);
const colorPicker = ref<Pickr | null>(null);

watchEffect(() => {
  if (colorPicker.value) {
    colorPicker.value.setColor(props.color);
  }
});

function createColorPicker() {
  if (colorPickerElement.value) {
    colorPicker.value = Pickr.create({
      el: colorPickerElement.value,
      theme: "nano",
      lockOpacity: !props.options?.opacity,
      defaultRepresentation: "HEXA",
      default: props.color,
      components: {
        // Main components
        hue: true,
        opacity: true,
        // opacity: !!props.options?.opacity,
        // Input / output Options
        interaction: {
          // hex: true,
          // rgba: true,
          input: true,
          cancel: true,
        },
      },
    });

    colorPicker.value.on(
      "init",
      (instance: {
        getRoot: () => { (): any; new (): any; interaction: { result: any } };
        applyColor: () => void;
        hide: () => void;
      }) => {
        const { result } = instance.getRoot().interaction;

        result.addEventListener(
          "keydown",
          (e: KeyboardEvent) => {
            if (e.key === "Enter") {
              instance.applyColor();
              instance.hide();
            }
          },
          { capture: true }
        );
      }
    );

    colorPicker.value.on("change", (color: any, _: any, instance: Pickr) => {
      instance.applyColor();
      emit("change", color.toHEXA().toString());
    });

    colorPicker.value.on("cancel", (instance: Pickr) => {
      emit("cancel");
      instance.hide();
    });
  }
}

onUnmounted(() => {
  document.getElementsByClassName("pcr-app")[0].remove();
});
</script>

<style lang="scss">
.pcr-app {
  @apply rounded-lg p-2.5;
  color: #000 !important;
}

.pcr-app[data-theme="nano"] .pcr-selection .pcr-color-chooser {
  grid-area: 3 / 1 / 2 / 3;
}

.pcr-app[data-theme="nano"] .pcr-selection .pcr-color-opacity {
  grid-area: 3 / 1 / 3 / 3;
}

.pcr-app[data-theme="nano"] .pcr-interaction input[type="text"] {
  flex-basis: 100%;
}

.pcr-app[data-theme="nano"] .pcr-interaction input {
  @apply rounded;
}

.pcr-app[data-theme="nano"] .pcr-interaction .pcr-result {
  @apply text-black;
}

.pcr-app[data-theme="nano"] .pcr-interaction .pcr-cancel {
  @apply ml-auto;
}

.pickr .pcr-button {
  @apply w-10 h-10 rounded-lg border border-gray-200;
}

.pickr .pcr-button::after {
  @apply w-10 h-10 rounded-lg border border-gray-200 transition-none;
}

.pickr .pcr-button::before {
  @apply w-10 h-10 rounded-lg border border-gray-200;
}
</style>
